// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as menuActions from 'common/modules/app/menu/actions';
import { ICONS } from 'common/constants';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import trim from 'validator/lib/trim';
import { FEATURES } from 'common/modules/app/features/constants';

export interface IMenuItem {
    id?: number;
    path?: string;
    exact?: boolean;
    icon?: ICONS;
    title: string;
    permission?: string[];
    subItems?: IMenuItem[];
    isActive?: boolean;
    isHighlighted?: boolean;
    isAlerted?: boolean;
    isOpened?: boolean;
    feature?: string;
}

export type MenuAction = ActionType<typeof menuActions>;
export type MenuState = Readonly<IMenuItem[]>;

const trimLocationPath = (str: string): string => {
    str = trim(str);

    if (str.substr(-1) === '/') {
        return str.substr(0, str.length - 1);
    }
    return str;
};

const mapper = (item: IMenuItem): IMenuItem => {
    item.isOpened = false;

    if (item.path) {
        item.path = trimLocationPath(ADMIN_ROUTE_PREFIX + item.path);
    }

    if (item.subItems && item.subItems.length > 0) {
        item.subItems.map(mapper);
    }

    return item;
};

export const initialMenuState: IMenuItem[] = [
    {
        id: 1,
        path: '/dashboard',
        icon: ICONS.DASHBOARD,
        title: 'menu.dashboard',
    },
    {
        id: 2,
        icon: ICONS.RESOURCE,
        title: 'menu.computeResources',
        path: '/compute_resources',
        permission: [PERMISSION_LIST.GET_COMPUTE_RESOURCES, PERMISSION_LIST.MANAGE_COMPUTE_RESOURCES],
        subItems: [
            {
                path: '/locations',
                title: 'menu.locations',
                permission: [PERMISSION_LIST.GET_LOCATIONS, PERMISSION_LIST.MANAGE_LOCATIONS],
            },
            {
                path: '/plans',
                title: 'menu.plans',
                permission: [PERMISSION_LIST.GET_PLANS, PERMISSION_LIST.MANAGE_PLANS],
            },
            {
                path: '/offers',
                title: 'menu.offers',
            },
        ],
    },
    {
        id: 3,
        icon: ICONS.SERVERS,
        title: 'menu.servers',
        path: '/servers',
        permission: [PERMISSION_LIST.MANAGE_SERVERS],
    },
    {
        id: 4,
        icon: ICONS.BACKUP,
        title: 'menu.backups',
        path: '/backups',
        permission: [
            PERMISSION_LIST.GET_BACKUP_NODES,
            PERMISSION_LIST.MANAGE_BACKUP_NODES,
            PERMISSION_LIST.GET_BACKUPS,
            PERMISSION_LIST.MANAGE_BACKUPS,
        ],
        subItems: [
            {
                path: '/backups',
                title: 'menu.serverBackups',
                permission: [
                    PERMISSION_LIST.GET_BACKUP_NODES,
                    PERMISSION_LIST.MANAGE_BACKUP_NODES,
                    PERMISSION_LIST.GET_BACKUPS,
                    PERMISSION_LIST.MANAGE_BACKUPS,
                ],
            },
            {
                path: '/management_node_backups',
                title: 'menu.managementNodeBackup',
                permission: [
                    PERMISSION_LIST.GET_BACKUP_NODES,
                    PERMISSION_LIST.MANAGE_BACKUP_NODES,
                    PERMISSION_LIST.GET_MANAGEMENT_NODE_BACKUPS,
                    PERMISSION_LIST.MANAGE_MANAGEMENT_NODE_BACKUPS,
                ],
            },
            {
                path: '/backup_nodes',
                title: 'menu.backupNodes',
                permission: [PERMISSION_LIST.GET_BACKUP_NODES, PERMISSION_LIST.MANAGE_BACKUP_NODES],
            },
        ],
    },
    {
        id: 5,
        icon: ICONS.RESOURCE,
        title: 'menu.storage',
        permission: [PERMISSION_LIST.MANAGE_STORAGE],
        subItems: [
            {
                path: '/storage',
                exact: true,
                title: 'menu.storage',
                permission: [PERMISSION_LIST.MANAGE_STORAGE],
            },
            {
                path: '/storage_tags',
                title: 'menu.storageTags',
                permission: [PERMISSION_LIST.MANAGE_STORAGE],
            },
        ],
    },
    {
        id: 8,
        icon: ICONS.CD,
        title: 'menu.images',
        permission: [
            PERMISSION_LIST.GET_OS_IMAGES,
            PERMISSION_LIST.MANAGE_OS_IMAGES,
            PERMISSION_LIST.GET_APPLICATIONS,
            PERMISSION_LIST.MANAGE_APPLICATIONS,
        ],
        subItems: [
            {
                id: 9,
                path: '/os_images',
                title: 'menu.oses',
                permission: [PERMISSION_LIST.GET_OS_IMAGES, PERMISSION_LIST.MANAGE_OS_IMAGES],
            },
            {
                id: 10,
                path: '/applications',
                title: 'menu.applications',
                permission: [PERMISSION_LIST.GET_APPLICATIONS, PERMISSION_LIST.MANAGE_APPLICATIONS],
            },
            {
                id: 42,
                path: '/iso_images',
                title: 'menu.isoImages',
                permission: [
                    PERMISSION_LIST.GET_ISO_IMAGES,
                    PERMISSION_LIST.MANAGE_ALL_ISO_IMAGES,
                    PERMISSION_LIST.MANAGE_OWNED_ISO_IMAGES,
                ],
            },
            {
                id: 43,
                path: '/image_presets',
                title: 'menu.imagePresets',
                feature: FEATURES.IMAGE_PRESETS,
                permission: [
                    PERMISSION_LIST.GET_IMAGE_PRESETS,
                    PERMISSION_LIST.MANAGE_APP_SETTINGS,
                ],
            },
        ],
    },
    {
        id: 11,
        icon: ICONS.IP_ADDRESSES,
        title: 'menu.network',
        permission: [PERMISSION_LIST.GET_IP_BLOCKS, PERMISSION_LIST.MANAGE_IP_BLOCKS],
        subItems: [
            {
                id: 12,
                path: '/ip_blocks',
                title: 'menu.ipBlocks',
                permission: [PERMISSION_LIST.GET_IP_BLOCKS, PERMISSION_LIST.MANAGE_IP_BLOCKS],
            },
            {
                id: 13,
                path: '/vlans',
                title: 'menu.vlans',
                permission: [PERMISSION_LIST.GET_IP_BLOCKS, PERMISSION_LIST.MANAGE_IP_BLOCKS],
            },
            {
                id: 39,
                path: '/vpc_networks',
                title: 'menu.vpcNetworks',
                permission: [PERMISSION_LIST.GET_VPC_NETWORKS, PERMISSION_LIST.MANAGE_ALL_VPC_NETWORKS],
            },
        ],
    },
    {
        id: 15,
        path: '/updates',
        icon: ICONS.ARROW_TRAY_DOWN,
        title: 'menu.updates',
        permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
    },
    {
        id: 16,
        icon: ICONS.GEAR,
        title: 'menu.settings',
        permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
        subItems: [
            {
                id: 34,
                path: '/settings/general',
                title: 'menu.generalSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 35,
                path: '/external_integrations',
                title: 'menu.externalIntegrations',
                permission: [PERMISSION_LIST.MANAGE_APPLICATIONS],
            },
            {
                id: 17,
                path: '/settings/dns',
                title: 'menu.dnsSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 18,
                path: '/settings/billing_integration',
                title: 'menu.billingIntegrationSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 19,
                path: '/settings/system_updates',
                title: 'menu.updatesSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 20,
                path: '/license',
                title: 'menu.license',
                permission: [PERMISSION_LIST.MANAGE_LICENSES],
            },
            {
                id: 21,
                path: '/settings/mail',
                title: 'menu.mailSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 22,
                path: '/settings/network_rules',
                title: 'menu.networkRulesSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 40,
                path: '/settings/vpc_network',
                title: 'menu.vpcNetworkSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 36,
                path: '/settings/notifications',
                title: 'menu.notificationsSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 23,
                path: '/settings/user_area',
                title: 'menu.userAreaSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 24,
                path: '/settings/compute_resource',
                title: 'menu.computeResourceSettings',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 14,
                path: '/icons',
                title: 'menu.icons',
                permission: [PERMISSION_LIST.GET_ICONS, PERMISSION_LIST.MANAGE_ICONS],
            },
            {
                id: 31,
                path: '/settings/languages',
                title: 'menu.languages',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 38,
                path: '/settings/event_handlers',
                title: 'menu.eventHandlers',
                permission: [PERMISSION_LIST.GET_APP_SETTINGS, PERMISSION_LIST.MANAGE_APP_SETTINGS],
            },
            {
                id: 41,
                path: '/settings/tags',
                title: 'menu.tags',
                permission: [PERMISSION_LIST.GET_TAGS, PERMISSION_LIST.MANAGE_TAGS],
            },
        ],
    },
    {
        id: 44,
        icon: ICONS.MAIL,
        path: '/mass-mailing',
        title: 'menu.massMailing',
        permissions: [PERMISSION_LIST.MASS_MAIL_USERS],
    },
    {
        id: 25,
        icon: ICONS.USER,
        title: 'menu.access',
        subItems: [
            {
                id: 26,
                path: '/users',
                title: 'menu.users',
                permission: [PERMISSION_LIST.GET_USERS, PERMISSION_LIST.MANAGE_USERS],
            }, {
                id: 27,
                path: '/roles',
                title: 'menu.roles',
                permission: [PERMISSION_LIST.GET_ROLES, PERMISSION_LIST.MANAGE_ROLES],
            }, {
                path: '/limit_groups',
                title: 'menu.limitGroups',
                permission: [PERMISSION_LIST.GET_LIMIT_GROUPS, PERMISSION_LIST.MANAGE_LIMIT_GROUPS],
            }, {
                id: 28,
                path: '/ssh_keys',
                title: 'menu.sshKeys',
                permission: [PERMISSION_LIST.GET_SSH_KEYS, PERMISSION_LIST.MANAGE_SSH_KEYS],
            }, {
                id: 32,
                path: '/api_tokens',
                title: 'menu.api_tokens',
                permission: [PERMISSION_LIST.MANAGE_API_TOKENS],
            }, {
                id: 33,
                path: '/activity_logs',
                title: 'menu.activity_logs',
                permission: [PERMISSION_LIST.MANAGE_ACTIVITY_LOGS],
            },
        ],
    },
    {
        id: 37,
        path: '/cluster_imports',
        icon: ICONS.TRANSFER,
        title: 'menu.imports',
        permission: [PERMISSION_LIST.GET_CLUSTER_IMPORTS, PERMISSION_LIST.MANAGE_CLUSTER_IMPORTS],
    },
    {
        id: 29,
        path: '/tasks',
        icon: ICONS.TASKS,
        title: 'menu.tasks',
        permission: [PERMISSION_LIST.GET_TASKS, PERMISSION_LIST.MANAGE_TASKS],
    },
    {
        id: 30,
        path: '/help',
        icon: ICONS.QUESTION,
        title: 'menu.help',
        permission: [PERMISSION_LIST.VIEW_HELP],
    },
].map(mapper);

type Result = [IMenuItem[], boolean];

const setActiveItem = (items: IMenuItem[], path: string): Result => {
    let found = false;

    const visitor = (item: IMenuItem) => {
        // Some path in admin area is not mapped to menu item, for example IP addresses
        // table fot IP Block, so we should handle this case.
        //
        // Also we have '/admin' path so we can't use just `statsWith` :(
        if (item.path) {
            item.isActive = item.exact ? item.path === path : path.startsWith(item.path);
            item.isOpened = item.isOpened || item.isActive;
            item.isHighlighted = item.isActive;
        }

        if (item.subItems) {
            const [subItems, isNestedActive] = setActiveItem(item.subItems as IMenuItem[], path);

            item.subItems = subItems;
            item.isActive = item.isActive || isNestedActive;
            item.isHighlighted = item.path ? item.isActive : isNestedActive;
        }

        found = found || !!item.isActive;

        return item;
    };

    return [items.map(visitor), found];
};

const toggleGroup = (items: MenuState, ids: number[], isOpened: boolean): Result => {
    let visitor: (item: IMenuItem, idx: number) => IMenuItem;
    let found = false;

    if (ids.length > 1) {
        const nextIds = ids.slice(1);

        visitor = (item: IMenuItem) => {
            if (!item.subItems) {
                return item;
            }

            const [subItems, isNestedOpen] = toggleGroup(
                item.subItems,
                nextIds,
                isOpened
            );

            found = found || isNestedOpen;

            return {
                ...item,
                subItems,
                isActive: item.isActive || (isOpened && isNestedOpen),
                isOpened,
            };
        };
    } else {
        // On level where required idx should be present.
        visitor = (item: IMenuItem) => {
            if (item.id === ids[0]) {
                item.isActive = isOpened;
                item.isOpened = isOpened;
                found = true;
            }

            return item;
        };
    }

    return [items.map(visitor), found];
};

const setItemAlerted = (items: IMenuItem[], path: string, alerted = true) => {
    const index = items.findIndex(item => (
        item.subItems ? setItemAlerted(item.subItems, path, alerted) : item.path === path)
    );

    if (index !== -1) {
        items[index].isAlerted = alerted;
    }
};

export default (state: IMenuItem[] = initialMenuState, action: MenuAction) => {
    let newState: IMenuItem[] = [...state];

    switch (action.type) {
    case getType(menuActions.setActiveItem):
        [newState] = setActiveItem(state, trimLocationPath(action.payload));
        return newState;

    case getType(menuActions.setAlertedItem):
        const { path, alerted } = action.payload;
        setItemAlerted(state, path, alerted);

        return newState;

    case getType(menuActions.toggleGroup):
        [newState] = toggleGroup(
            state,
            action.payload.ids,
            action.payload.isOpened
        );
        return newState;
    default:
        return state;
    }
};
