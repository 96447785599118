// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { ReinstallTab } from 'common/components/ServerTabs/ReinstallTab/ReinstallTab';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { createSshKey } from 'common/modules/sshKey/actions';
import { COLORS } from 'admin/core/theme';
import { ComputeResourceVmStatus } from 'common/api/resources/ComputeResourceVm';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { generatePassword } from 'common/helpers/vm';
import { FEATURES } from 'common/modules/app/features/constants';
import * as toasterActions from 'common/modules/app/toaster/actions';

const mapStateToProps = (state: RootState) => ({
    server: state.computeResourceVm.item,
    applications: state.application.list,
    osImages: state.osImage.list,
    loadingFlags: {
        isLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_REINSTALL),
        isVmReinstalling: state.app.loadingFlags.has(LOADING_FLAGS.REINSTALL_COMPUTE_RESOURCE_VM) ||
            state.computeResourceVm.item.status === ComputeResourceVmStatus.REINSTALLING,
    },
    hideUserData: false,
    sshKeys: state.sshKey.data,
    mountDeps: [],
    buttonColor: COLORS.PRIMARY,
    generatePasswd: generatePassword,
    isImagePresetsEnabled: state.app.features.has(FEATURES.IMAGE_PRESETS), // todo: SVM2-6052
    allowPasswordInput: true,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    bakeToast: bindActionCreators(toasterActions.bakeForegroundToast, dispatch),
    loadReinstallServerPageData: bindActionCreators(computeResourceVmActions.loadComputeResourceVmReinstallPageData, dispatch),
    reinstallServer: bindActionCreators(computeResourceVmActions.reinstallComputeResourceVm, dispatch),
    createSshKey: bindActionCreators(createSshKey, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReinstallTab);
