// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    massMailing,
    IMassMailUsersRequest,
} from 'common/api/resources/MassMailing';
import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/massMailing/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';

export const sentEmailsToUsers = createCustomAction(
    types.SENT_EMAILS_TO_USERS,
    () => ({ payload: {} })
);

export const sendEmailToSelectedUsers = (data: IMassMailUsersRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.MASS_MAIL_SENDING));
    try {
        const result = await massMailing.post(data);
        dispatch(sentEmailsToUsers());
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'massMailing.formSubmitSuccess')(dispatch);
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.MASS_MAIL_SENDING));
    }
};