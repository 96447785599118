// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

// todo: SVM2-6052
// we will merge them into one class when finalizing the implementation
// eslint-disable-next-line max-classes-per-file
import { IPlanResponse } from 'common/api/resources/Plan';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import { ILocationResponse } from 'common/api/resources/Location';
import { IApplicationResponse } from 'common/api/resources/Application';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { IOfferResponse } from 'common/api/resources/Offer';
import { IVpcNetworkResponse } from 'common/api/resources/VpcNetwork';
import { IOsImageVersionResponse } from 'common/api/resources/OsImageVersion';
import { ComputeResourceVmCustomPlan } from 'common/api/resources/ComputeResourceVm';
import {
    createContext,
    useContext,
} from 'react';

type ManagedResponseEntity = IPlanResponse
    | IOsImageVersionResponse
    | IApplicationResponse
    | ILocationResponse
    | IOfferResponse
    | IVpcNetworkResponse;
type ManagedEntity = ManagedResponseEntity| ComputeResourceVmCustomPlan;

export interface IManagerOfDisabledEntitiesRecords {
    plans?: IPlanResponse[];
    osImages?: IOsImageResponse[];
    applications?: IApplicationResponse[];
    locations?: ILocationResponse[];
    offers?: IOfferResponse[];
    vpcNetworks?: IVpcNetworkResponse[];
}

export interface IManagerOfDisabledEntitiesSelection<T extends keyof ManagedResponseEntity | undefined = undefined> {
    plan?: (T extends keyof IPlanResponse ? IPlanResponse[T] : IPlanResponse) | ComputeResourceVmCustomPlan;
    osImageVersion?: T extends keyof IOsImageVersionResponse ? IOsImageVersionResponse[T] : IOsImageVersionResponse;
    application?: T extends keyof IApplicationResponse ? IApplicationResponse[T] : IApplicationResponse;
    location?: T extends keyof ILocationResponse ? ILocationResponse[T] : ILocationResponse;
    offers?: T extends keyof IOfferResponse ? Array<IOfferResponse[T]> : IOfferResponse[];
    vpcNetworks?: T extends keyof IVpcNetworkResponse ? Array<IVpcNetworkResponse[T]> : IVpcNetworkResponse[];
}

export interface IManagerOfDisabledEntities {
    getDisabledPlanIds(): Array<IPlanResponse['id']>;
    getDisabledOsImageVersionIds(): Array<IOsImageVersionResponse['id']>;
    getDisabledApplicationIds(): Array<IApplicationResponse['id']>;
    getDisabledLocationIds(): Array<ILocationResponse['id']>;
    getDisabledOfferIds(): Array<IOfferResponse['id']>;
    getDisabledVpcNetworkIds(): Array<IVpcNetworkResponse['id']>;

    getPlanConflicts(plan: IPlanResponse): ManagedEntity[];
    getOsImageVersionConflicts(osImageVersion: IOsImageVersionResponse): ManagedEntity[];
    getApplicationConflicts(application: IApplicationResponse): ManagedEntity[];
    getLocationConflicts(location: ILocationResponse): ManagedEntity[];
    getOfferConflicts(offer: IOfferResponse): ManagedEntity[];
    getVpcNetworkConflicts(vpcNetwork: IVpcNetworkResponse): ManagedEntity[];
}

export default class ManagerOfDisabledEntities implements IManagerOfDisabledEntities {
    protected records: IManagerOfDisabledEntitiesRecords;
    protected selectedIds: IManagerOfDisabledEntitiesSelection<'id'>;
    protected selectedEntities: IManagerOfDisabledEntitiesSelection;

    constructor(records: IManagerOfDisabledEntitiesRecords, selection: IManagerOfDisabledEntitiesSelection<'id'>) {
        this.records = records;
        this.selectedIds = selection;
        this.selectedEntities = this.fillSelectedEntities();
    }

    getPlanConflicts(plan: IPlanResponse) {
        const conflicts: ManagedEntity[] = [];

        // Check if plan is disabled by selected OS image version
        if (this.selectedIds.osImageVersion && !plan.available_os_image_versions?.some(item => item.id === this.selectedIds.osImageVersion)) {
            conflicts.push(this.selectedEntities.osImageVersion!);
        }

        // Check if plan is disabled by selected application
        if (this.selectedIds.application && !plan.available_applications?.some(item => item.id === this.selectedIds.application)) {
            conflicts.push(this.selectedEntities.application!);
        }

        // Check if plan is disabled by selected location
        if (this.selectedIds.location && !plan.available_locations?.some(item => item.id === this.selectedIds.location)) {
            conflicts.push(this.selectedEntities.location!);
        }

        // Check if plan is disabled by selected offers
        if (this.selectedEntities.offers && this.selectedEntities.offers.length > 0) {
            for (const offer of this.selectedEntities.offers) {
                if (!offer.available_plans?.some(item => item.id === plan.id)) {
                    conflicts.push(offer);
                }
            }
        }

        return conflicts;
    }

    isPlanDisabled(plan: IPlanResponse|number) {
        const planEntity = typeof plan === 'number'
            ? this.records.plans?.find(item => item.id === plan)
            : plan;

        if (!planEntity) {
            return false;
        }

        return this.getPlanConflicts(planEntity).length > 0;
    }

    getDisabledPlanIds() {
        if (this.records.plans === undefined) {
            return [];
        }

        return this.records.plans
            .filter(plan => this.isPlanDisabled(plan))
            .map(item => item.id);
    }

    getOsImageVersionConflicts(osImageVersion: IOsImageVersionResponse) {
        const conflicts: ManagedEntity[] = [];

        if (this.selectedEntities.plan && this.selectedEntities.plan.virtualization_type !== osImageVersion.virtualization_type) {
            conflicts.push(this.selectedEntities.plan!);
        }

        // return early if plan is custom
        if (this.selectedEntities.plan?.is_custom) {
            return conflicts;
        }

        // Check if OS image version is disabled by plan
        // If OS image version `available_plans` does not contain selected `planId`, disable it
        if (this.selectedEntities.plan && !osImageVersion.available_plans?.some(item => item.id === this.selectedIds.plan)) {
            conflicts.push(this.selectedEntities.plan);
        }

        // Check if OS image version is disabled by location
        // If OS image version `available_locations` does not contain selected `locationId`, disable it
        if (this.selectedEntities.location && this.selectedEntities.location.available_plans?.every(item => !osImageVersion.available_plans?.some(plan => plan.id === item.id))) {
            conflicts.push(this.selectedEntities.location);
        }

        return conflicts;
    }

    isOsImageVersionDisabled(osImageVersion: IOsImageVersionResponse|number) {
        const osImageVersionEntity = typeof osImageVersion === 'number'
            ? this.records.osImages
                ?.map(item => item.versions)
                .flat(1)
                .find(item => item.id === osImageVersion)
            : osImageVersion;

        if (!osImageVersionEntity) {
            return false;
        }

        return this.getOsImageVersionConflicts(osImageVersionEntity).length > 0;
    }

    getDisabledOsImageVersionIds() {
        if (this.records.osImages === undefined) {
            return [];
        }

        return this.records.osImages
            .map(item => item.versions)
            .flat(1)
            .filter(osImageVersion => this.isOsImageVersionDisabled(osImageVersion))
            .map(item => item.id);
    }

    getApplicationConflicts(application: IApplicationResponse): ManagedEntity[] {
        const conflicts: ManagedEntity[] = [];

        // If plan virtualization type is VZ, disable all applications
        if (this.selectedEntities.plan && this.selectedEntities.plan.virtualization_type === VirtualizationType.VZ) {
            conflicts.push(this.selectedEntities.plan!);
        }

        // return early if plan is custom
        if (this.selectedEntities.plan?.is_custom) {
            return conflicts;
        }

        // Check if application is disabled by plan
        // If application `available_plans` does not contain selected `planId`, disable it
        if (this.selectedIds.plan && !application.available_plans?.some(item => item.id === this.selectedIds.plan)) {
            conflicts.push(this.selectedEntities.plan!);
        }

        // Check if application is disabled by location
        // If application `available_locations` does not contain selected `locationId`, disable it
        if (this.selectedEntities.location && this.selectedEntities.location.available_plans?.every(item => !application.available_plans?.some(plan => plan.id === item.id))) {
            conflicts.push(this.selectedEntities.location);
        }

        return conflicts;
    }

    isApplicationDisabled(application: IApplicationResponse|number) {
        const applicationEntity = typeof application === 'number'
            ? this.records.applications?.find(item => item.id === application)
            : application;

        if (!applicationEntity) {
            return false;
        }

        return this.getApplicationConflicts(applicationEntity).length > 0;
    }

    getDisabledApplicationIds() {
        if (this.records.applications === undefined) {
            return [];
        }

        return this.records.applications
            .filter(application => this.isApplicationDisabled(application))
            .map(item => item.id);
    }

    getLocationConflicts(location: ILocationResponse): ManagedEntity[] {
        const conflicts: ManagedEntity[] = [];

        // Check if location is disabled by plan
        // If location `available_plans` does not contain selected `planId`, disable it
        if (this.selectedEntities.plan
            && !this.selectedEntities.plan.is_custom
            && !location.available_plans?.some(item => item.id === this.selectedIds.plan)
        ) {
            conflicts.push(this.selectedEntities.plan);
        }

        // Check if location is disabled by offers
        // If any of selected offers does not have this location, disable it
        if (this.selectedEntities.offers && this.selectedEntities.offers.length > 0) {
            for (const offer of this.selectedEntities.offers) {
                if (!offer.available_locations?.some(item => item.id === location.id)) {
                    conflicts.push(offer);
                }
            }
        }

        // Check if location is disabled by VPC networks
        // If selected VPC networks are available only in other locations, disable it
        if (this.selectedEntities.vpcNetworks && this.selectedEntities.vpcNetworks.length > 0) {
            for (const vpcNetwork of this.selectedEntities.vpcNetworks) {
                if (vpcNetwork.location.id !== location.id) {
                    conflicts.push(vpcNetwork);
                }
            }
        }

        const osImageVersionId = this.selectedEntities.osImageVersion?.id;
        if (osImageVersionId) {
            if (location.available_plans?.length === 0) {
                conflicts.push(this.selectedEntities.osImageVersion!);
            }

            let hasCompatiblePlan = false;

            for (const availablePlan of location.available_plans!) {
                const realPlan = this.records.plans?.find(plan => plan.id === availablePlan.id);
                if (!realPlan) {
                    continue;
                }

                const compatiblePlanWithinLocation = realPlan?.available_os_image_versions?.find(item => item.id === osImageVersionId);
                if (compatiblePlanWithinLocation) {
                    hasCompatiblePlan = true;
                    break;
                }
            }

            if (!hasCompatiblePlan) {
                conflicts.push(this.selectedEntities.osImageVersion!);
            }
        }

        const applicationId = this.selectedEntities.application?.id;
        if (applicationId) {
            if (location.available_plans?.length === 0) {
                conflicts.push(this.selectedEntities.application!);
            }

            let hasCompatiblePlan = false;

            for (const availablePlan of location.available_plans!) {
                const realPlan = this.records.plans?.find(plan => plan.id === availablePlan.id);
                if (!realPlan) {
                    continue;
                }

                const compatiblePlanWithinLocation = realPlan?.available_applications?.find(item => item.id === applicationId);
                if (compatiblePlanWithinLocation) {
                    hasCompatiblePlan = true;
                    break;
                }
            }

            if (!hasCompatiblePlan) {
                conflicts.push(this.selectedEntities.application!);
            }
        }

        return conflicts;
    }

    isLocationDisabled(location: ILocationResponse|number) {
        const locationEntity = typeof location === 'number'
            ? this.records.locations?.find(item => item.id === location)
            : location;

        if (!locationEntity) {
            return false;
        }

        return this.getLocationConflicts(locationEntity).length > 0;
    }

    getDisabledLocationIds() {
        if (this.records.locations === undefined) {
            return [];
        }

        return this.records.locations
            .filter(location => this.isLocationDisabled(location))
            .map(item => item.id);
    }

    getOfferConflicts(offer: IOfferResponse): ManagedEntity[] {
        const conflicts: ManagedEntity[] = [];

        // Check if offer is disabled by plan
        // If offer `available_plans` does not contain selected `planId`, disable it
        if (this.selectedIds.plan && !offer.available_plans?.some(item => item.id === this.selectedIds.plan)) {
            conflicts.push(this.selectedEntities.plan!);
        }

        // Check if offer is disabled by location
        // If offer `available_locations` does not contain selected `locationId`, disable it
        if (this.selectedIds.location && !offer.available_locations?.some(item => item.id === this.selectedIds.location)) {
            conflicts.push(this.selectedEntities.location!);
        }

        return conflicts;
    }

    isOfferDisabled(offer: IOfferResponse|number) {
        const offerEntity = typeof offer === 'number'
            ? this.records.offers?.find(item => item.id === offer)
            : offer;

        if (!offerEntity) {
            return false;
        }

        return this.getOfferConflicts(offerEntity).length > 0;
    }

    getDisabledOfferIds() {
        if (this.records.offers === undefined) {
            return [];
        }

        return this.records.offers
            .filter(offer => this.isOfferDisabled(offer))
            .map(item => item.id);
    }

    getVpcNetworkConflicts(vpcNetwork: IVpcNetworkResponse): ManagedEntity[] {
        const conflicts: ManagedEntity[] = [];

        // Check if VPC network is disabled by location
        // If VPC network `location` does not match selected `locationId`, disable it
        if (this.selectedIds.location && vpcNetwork.location.id !== this.selectedIds.location) {
            conflicts.push(this.selectedEntities.location!);
        }

        return conflicts;
    }

    isVpcNetworkDisabled(vpcNetwork: IVpcNetworkResponse|number) {
        const vpcNetworkEntity = typeof vpcNetwork === 'number'
            ? this.records.vpcNetworks?.find(item => item.id === vpcNetwork)
            : vpcNetwork;

        if (!vpcNetworkEntity) {
            return false;
        }

        return this.getVpcNetworkConflicts(vpcNetworkEntity).length > 0;
    }

    getDisabledVpcNetworkIds() {
        if (this.records.vpcNetworks === undefined) {
            return [];
        }

        return this.records.vpcNetworks
            .filter(vpcNetwork => this.isVpcNetworkDisabled(vpcNetwork))
            .map(item => item.id);
    }

    private fillSelectedEntities(): IManagerOfDisabledEntitiesSelection {
        return {
            plan: (typeof this.selectedIds.plan === 'object')
                ? this.selectedIds.plan
                : this.records.plans?.find(plan => plan.id === this.selectedIds.plan),
            osImageVersion: this.records.osImages
                ?.map(item => item.versions)
                .flat(1)
                .find(osImageVersion => osImageVersion.id === this.selectedIds.osImageVersion),
            application: this.records.applications?.find(app => app.id === this.selectedIds.application),
            location: this.records.locations?.find(loc => loc.id === this.selectedIds.location),
            offers: (this.records.offers ?? []).filter(offer => this.selectedIds.offers?.includes(offer.id)),
            vpcNetworks: (this.records.vpcNetworks ?? []).filter(vpc => this.selectedIds.vpcNetworks?.includes(vpc.id)),
        };
    }
}

export class WithImagePresetsManagerOfDisabledEntities extends ManagerOfDisabledEntities {
    getPlanConflicts(plan: IPlanResponse): ManagedEntity[] {
        const conflicts = [];

        // Check if plan is disabled by selected OS image version through image presets
        if (this.selectedEntities.osImageVersion) {
            const osImageVersionImagePresetIds = this.selectedEntities.osImageVersion.image_presets?.map(item => item.id).sort();
            const planImagePresetIds = 'image_presets' in plan
                ? plan.image_presets.map(item => item.id).sort()
                // @ts-ignore
                : plan.image_preset_ids?.sort();

            // OS Image Version must have at least one image preset in common with the plan
            if (!osImageVersionImagePresetIds?.some(item => planImagePresetIds?.includes(item))) {
                conflicts.push(this.selectedEntities.osImageVersion!);
            }
        }

        // Check if plan is disabled by selected Application through image presets
        if (this.selectedEntities.application) {
            const applicationImagePresetIds = this.selectedEntities.application.image_presets?.map(item => item.id).sort();
            const planImagePresetIds = 'image_presets' in plan
                ? plan.image_presets.map(item => item.id).sort()
                // @ts-ignore
                : plan.image_preset_ids?.sort();

            // Application must have at least one image preset in common with the plan
            if (!applicationImagePresetIds?.some(item => planImagePresetIds?.includes(item))) {
                conflicts.push(this.selectedEntities.application!);
            }
        }

        // Check if plan is disabled by selected location
        if (this.selectedIds.location && !plan.available_locations?.some(item => item.id === this.selectedIds.location)) {
            conflicts.push(this.selectedEntities.location!);
        }

        // Check if plan is disabled by selected offers
        if (this.selectedEntities.offers && this.selectedEntities.offers.length > 0) {
            for (const offer of this.selectedEntities.offers) {
                if (!offer.available_plans?.some(item => item.id === plan.id)) {
                    conflicts.push(offer);
                }
            }
        }

        return conflicts;
    }

    getOsImageVersionConflicts(osImageVersion: IOsImageVersionResponse): ManagedEntity[] {
        const conflicts = [];

        if (this.selectedEntities.plan) {
            if (this.selectedEntities.plan.virtualization_type !== osImageVersion.virtualization_type) {
                conflicts.push(this.selectedEntities.plan);
            }

            const osImageVersionImagePresetIds = osImageVersion.image_presets?.map(item => item.id).sort();
            const planImagePresetIds = 'image_presets' in this.selectedEntities.plan
                ? this.selectedEntities.plan.image_presets.map(item => item.id).sort()
                : this.selectedEntities.plan.image_preset_ids?.sort();

            // OS Image Version must have at least one image preset in common with the plan
            if (!osImageVersionImagePresetIds?.some(item => planImagePresetIds?.includes(item))) {
                conflicts.push(this.selectedEntities.plan);
            }
        }

        return conflicts;
    }

    getApplicationConflicts(application: IApplicationResponse): ManagedEntity[] {
        const conflicts = [];

        if (this.selectedEntities.plan) {
            if (this.selectedEntities.plan.virtualization_type === VirtualizationType.VZ) {
                conflicts.push(this.selectedEntities.plan);
            }

            const applicationImagePresetIds = application.image_presets?.map(item => item.id).sort();
            const planImagePresetIds = 'image_presets' in this.selectedEntities.plan
                ? this.selectedEntities.plan.image_presets.map(item => item.id).sort()
                : this.selectedEntities.plan.image_preset_ids?.sort();

            // Application must have at least one image preset in common with the plan
            if (!applicationImagePresetIds?.some(item => planImagePresetIds?.includes(item))) {
                conflicts.push(this.selectedEntities.plan);
            }
        }

        return conflicts;
    }

    getLocationConflicts(location: ILocationResponse): ManagedEntity[] {
        const conflicts = [];

        // Check if location is disabled by plan
        // If location `available_plans` does not contain selected `planId`, disable it
        if (this.selectedEntities.plan && !location.available_plans?.some(item => item.id === this.selectedIds.plan)) {
            conflicts.push(this.selectedEntities.plan);
        }

        // Check if location is disabled by offers
        // If any of selected offers does not have this location, disable it
        if (this.selectedEntities.offers && this.selectedEntities.offers.length > 0) {
            for (const offer of this.selectedEntities.offers) {
                if (!offer.available_locations?.some(item => item.id === location.id)) {
                    conflicts.push(offer);
                }
            }
        }

        // Check if location is disabled by VPC networks
        // If selected VPC networks are available only in other locations, disable it
        if (this.selectedEntities.vpcNetworks && this.selectedEntities.vpcNetworks.length > 0) {
            for (const vpcNetwork of this.selectedEntities.vpcNetworks) {
                if (vpcNetwork.location.id !== location.id) {
                    conflicts.push(vpcNetwork);
                }
            }
        }

        // check if location is disabled by the selected OS image version
        if (this.selectedEntities.osImageVersion) {
            // location have to have at least one plan that have at least one image preset in common
            // with the selected OS image version

            const osImageVersionImagePresetIds = this.selectedEntities.osImageVersion.image_presets?.map(item => item.id).sort();
            const locationPlanIds = location.available_plans?.map(item => item.id) ?? [];
            const locationPlans = this.records.plans?.filter(item => locationPlanIds.includes(item.id));
            const locationPlansWithImagePreset = locationPlans?.filter(plan => {
                const planImagePresetIds = 'image_presets' in plan
                    ? plan.image_presets.map(item => item.id).sort()
                    // @ts-ignore
                    : plan.image_preset_ids?.sort();

                return osImageVersionImagePresetIds?.some(item => planImagePresetIds?.includes(item));
            });

            if (!locationPlansWithImagePreset?.length) {
                conflicts.push(this.selectedEntities.osImageVersion);
            }
        }

        // check if location is disabled by the selected application
        if (this.selectedEntities.application) {
            // location have to have at least one plan that have at least one image preset in common
            // with the selected application

            const applicationImagePresetIds = this.selectedEntities.application.image_presets?.map(item => item.id).sort();
            const locationPlanIds = location.available_plans?.map(item => item.id) ?? [];
            const locationPlans = this.records.plans?.filter(item => locationPlanIds.includes(item.id));
            const locationPlansWithImagePreset = locationPlans?.filter(plan => {
                const planImagePresetIds = 'image_presets' in plan
                    ? plan.image_presets.map(item => item.id).sort()
                    // @ts-ignore
                    : plan.image_preset_ids?.sort();

                return applicationImagePresetIds?.some(item => planImagePresetIds?.includes(item));
            });

            if (!locationPlansWithImagePreset?.length) {
                conflicts.push(this.selectedEntities.application);
            }
        }

        return conflicts;
    }
}

export const DisabledEntitiesManagerContext = createContext<IManagerOfDisabledEntities | undefined>(undefined);

export const useDisabledEntitiesManager = () => {
    const manager = useContext(DisabledEntitiesManagerContext);
    if (!manager) {
        throw new Error('DisabledEntitiesManagerContext is not provided');
    }

    return manager;
};
