// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const ENABLE_FEATURE = 'features/ENABLE_FEATURE';
export const DISABLE_FEATURE = 'features/DISABLE_FEATURE';

export enum FEATURES {
    NONE = 'none', // Useful for tests. Should not be used in production code.
    BACKUP_RESTORING = 'backup_restoring',
    DIRECT_VNC = 'direct-vnc',
    IMAGE_PRESETS = 'image-presets',
    IGNORE_COMPATIBILITY_CHECK = 'ignore-compatibility-check',
}
